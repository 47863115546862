import axiosBEInstance from '../utils/axios-backend'

export default class HttpBackendService {

    get(url:string,signal?:AbortSignal,params?:any){
        return axiosBEInstance.get(url,{
            signal,
            params
        })
    }

    post(url:string, body:any, params = {} ,signal?:AbortSignal){
        return axiosBEInstance.post(url,body,{
            signal,
            params
        })
    }

    delete(url:string,id:string,signal?:AbortSignal){
        if(id) url = `${url}/${id}`;
        return axiosBEInstance.post(url,{
            signal
        })
    }
    
}