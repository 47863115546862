import {API, SW_API} from './constants.types'

// export const BE_HOST:string = process.env.REACT_APP_BE_HOST ? process.env.REACT_APP_BE_HOST : "http://localhost:5500/"

// export const SW_HOST:string = process.env.REACT_APP_SW_HOST ? process.env.REACT_APP_SW_HOST : "https://smartweb-ecms.tabsquare.com/"

const currentHost = window.location.hostname || "";
let BE_HOST1 = "https://smartpay-backend.tabsquare.com/"
let SW_HOST1 = "https://smartweb-ecms.tabsquare.com/"
let SW_BE_HOST1 = "https://smartweb-backend.tabsquare.com"
let LD_KEY = "63fdba1857945e13d9668ac1";
let DD = {
    CLIENT_TOKEN: "pub539af6574d471cb4493dc35b26ed8d83",
    SERVICE_NAME: "smartpay-fe-prod",
    DD_SITE: "datadoghq.eu",
    DD_ENABLE: true
}
if (currentHost.indexOf("stg") > -1 || currentHost.indexOf("stage") > -1 || currentHost.indexOf("127.0.0.1") > -1 || currentHost.indexOf("localhost") > -1) {
    BE_HOST1 = "https://smartpay-backend.stage.tabsquare.dev/"
    SW_HOST1 = "https://smartweb-ecms.stage.tabsquare.dev/"
    SW_BE_HOST1 = "https://smartweb-be.stage.tabsquare.dev/"
    LD_KEY = "6406eed191f9b01348aa9b2c";
    DD.CLIENT_TOKEN = "pub539af6574d471cb4493dc35b26ed8d83";
    DD.SERVICE_NAME = "smartpay-fe-stag";
} else if(currentHost.indexOf("uat") > -1) {
    BE_HOST1 = "https://smartpay-backend.uat.tabsquare.dev/"
    SW_HOST1 = "https://smartweb-ecms.uat.tabsquare.dev/"
    SW_BE_HOST1 = "https://smartweb-be.uat.tabsquare.dev"
    LD_KEY = "63fdba2657945e13d9668ae7";
    DD.CLIENT_TOKEN = "pub539af6574d471cb4493dc35b26ed8d83";
    DD.SERVICE_NAME = "smartpay-fe-uat";
} 

export const BE_HOST = BE_HOST1
export const SW_HOST = SW_HOST1
export const SW_BE_HOST = SW_BE_HOST1
export const LD_CLIENT_SIDE_KEY = LD_KEY;
export const DD_CONFIG = DD;

export const BE_API:API = {
    SCAN_TOKEN: "/v1/scan",
    SETTING:'/v1/settings',
    SETTING_V2:'/v2/settings',
    BILL:'/v1/bill',
    INITIATE_PAYMENT: '/v1/bill/payment',
    PAYMENT_NOTIF: '/v1/payment/notification',
    PAYMENT_STATUS: '/v1/bill/payment',
    SEND_BILL: '/v1/bill/send',
    PROXY: 'proxy/',
    VALIDATE_APPLE: '/v1/validate/checkout-apple',
    SPLIT_INFO: '/v1/payment/refreshsplitbill'
}

export const SW_BE_API:SW_API = {
    PAYMENT_SETTINGS: (merchantKey: string) => {
        if(!merchantKey) {
            return null;
        }
        return `/api/v1/merchants/${merchantKey}/payment-settings`;
    }
}

export const PAYMENT_STATUS_MAX_API_CALLS= 60 // IN COUNT

export const GET_BILL_MAX_TIME_LIMIT_DIFF = 30 // IN SECONDS

export const MAX_REFRESH_DISABLE_TIME = 30000 // IN MILLI SECONDS

export const SUPPORTED_LANGS = ['en']

export const PRIVACY_POLICY = "https://tabsquare.ai/smw-privacy-policy_"

export const TERMS_OF_USE = "https://tabsquare.ai/smw-terms-of-use_"

export const REGEX_EMAIL = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_TIP_AMT = /^\d*(\.\d+)?$/;

export const TIME_FORMAT = 'HH:mm:ss'

export const YEAR_FORMAT = 'YYYY-MM-DD'

export const PERCENTAGE_TIP = 'p'

export const CUSTOM_TIP = 'o'

export const NO_TIP = 'n'

export const PAYMENT_ACTION = 'p'

export const REFRESH_ACTION = 'r'

export const MAX_TIP_AMOUNT_0_CASE = 100

export const PAYMENT_CHANNEL = {
    STRIPE: 'STRIPE',
    RAZER: 'RAZER',
    CHECKOUT: 'CHECKOUT',
    CHECKOUT_GOOGLE_PAY: 'CHECKOUT_GOOGLE_PAY',
    CHECKOUT_APPLE_PAY: 'CHECKOUT_APPLE_PAY'
}

export const CHECK_BILL = true;

export const PAYMENT_INIT_ACTION = {
    PAYMENT_INIT: "PAYMENT_INIT",
    PAYMENT_POPUP: "PAYMENT_POPUP",
    EMAIL_POPUP_CONT: "EMAIL_POPUP_CONT",
    EMAIL_POPUP_SKIP: "EMAIL_POPUP_SKIP",
    BILL_CHANGE_CONT: "BILL_CHANGE_CONT",
    CHECKOUT_LOADING: "CHECKOUT_LOADING",
    CHECKOUT_INIT: "CHECKOUT_INIT",
    CHECKOUT_GOOGLE_INIT:"CHECKOUT_GOOGLE_INIT",
    CHECKOUT_APPLE_INIT:"CHECKOUT_APPLE_INIT"
}

export const CHECKOUT_CDN = "https://cdn.checkout.com/js/framesv2.min.js";

export const CHECKOUT_LANG_MAP = {
    'en': "EN-GB",
    'ar': "AR"
}

export const GOOGLE_PAY_CDN = "https://pay.google.com/gp/p/js/pay.js"

export const SPLIT_TYPE = {
    CUSTOM: 'CUSTOM',
    EQUAL: 'EQUAL'
}

export const SPLIT_THRESHOLD = 1;

export const MIN_NO_OF_PEOPLE_ON_TABLE = 2;

export const MIN_NO_OF_PEOPLE_PAYING_FOR = 2;

export const NAME_REGEX = /^(?!\p{Zs})(?!.*\p{Zs}$)[\p{L}\p{M}\p{Zs}]{1,30}$/u

export const ROUNDING_RULE = {
    HUNDRED: "HUNDRED",
    NTD: "NTD"
}

export const BILL_CHANGE_POPUP = {
    NO_SPLIT: 'NO_SPLIT',
    BILL_CHANGE: 'BILL_CHANGE',
    BALANCE_CHANGE: 'BALANCE_CHANGE'
}

export const WITH_TIP = true;

export const PAGES = {
    LANDING:'LANDING',
    BILL: 'BILL'
}

export const EVENTS = {
    V1_SCAN_QR_INFO: 'V1_SCAN_QR_INFO',
    V1_SCAN_SETTING: 'V1_SCAN_SETTING',
    V2_SCAN: 'V2_SCAN',
    PAGE_NOT_FOUND:'PAGE_NOT_FOUND',
    INITIATE_PAYMENT: 'INITIATE_PAYMENT'
}

export const LOADING = true;

export enum COUNTRY_CODE {
    UAE = "AE",
    SG = "SG"
}

export enum FEATURE_FLAGS {
    SMTWB_6822_DIRECT_PAYMENT = 'smtwb-6822-direct-payment'
}

export enum PAYMENT_LAYOUT_TYPE {
    DEFAULT = 'DEFAULT',
    DIRECT = 'DIRECT'
}
