import React, {useEffect} from 'react';
import RouteManager from "./route-manager/route-manager";
import { info, initDataDogBrowserLog } from './utils/dd-setup'
import { useErrorHandler } from 'react-error-boundary';
import {useDispatch, useSelector} from 'react-redux'
import { actionCreators, State } from './store';
import { Helmet } from 'react-helmet-async';
import { connectionImage, hexToRGB } from './utils/helpers';
import { useLocation } from 'react-router-dom';
import { toJSON } from 'css-convert-json';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { LOADING, TIME_FORMAT, YEAR_FORMAT } from './data/constants';

initDataDogBrowserLog()

function App() {
  const handleError = useErrorHandler()
  const {setting:{settingData},gLoading: {settingLoading}, gError:{settingError}} = useSelector((state:State)=> state)
  const dispatch = useDispatch()
  const { settingData:getSettingData,setSettingLoad }  = bindActionCreators(actionCreators, dispatch)
  const location = useLocation()
  
  useEffect(()=>{
    localStorage.setItem('connection',connectionImage())
    window.addEventListener('online', ()=>{
      if(localStorage.getItem('offline')){
        info(`Offline timestamp ${localStorage.getItem('offline')} merchant key - ${settingData?.merchantKey} session id - ${settingData?.xSpSessionID}`)
        info(`Online timestamp ${moment(new Date()).format(`${YEAR_FORMAT} ${TIME_FORMAT}`)} merchant key - ${settingData?.merchantKey} session id - ${settingData?.xSpSessionID}`)
        localStorage.removeItem('offline')
        window.location.reload()
      }
    });
    window.addEventListener('offline', ()=>{
      handleError(new Error('No Internet'))
      localStorage.setItem('offline',`${moment(new Date()).format(`${YEAR_FORMAT} ${TIME_FORMAT}`)}`)
    });

    if(!location.pathname.includes('scan') && !location.pathname.includes('merchant')){
      if(!settingData){
        getSettingData()
      }else{
        setSettingLoad(!LOADING)
      }
    }

    const root = document.documentElement;
    root?.style.setProperty('--colorPrimary', '255, 107, 0');
    root?.style.setProperty('--colorPrimaryHover', '255, 168, 0');
    root?.style.setProperty('--colorPrimaryRGB', '');
    root?.style.setProperty('--colorPrimaryHoverRGB', '');
    root?.style.setProperty('--colorSuccess', '0, 178, 95');
    root?.style.setProperty('--colorSuccessRGB', '');
    root?.style.setProperty('--colorWarning', '250, 143, 12');
    root?.style.setProperty('--colorWarningRGB', '');
    root?.style.setProperty('--colorError', '209, 0, 0');
    root?.style.setProperty('--colorErrorRGB', '');
    root?.style.setProperty('--colorBg', '237, 238, 242');
    root?.style.setProperty('--colorBgRGB', '');
    root?.style.setProperty('--colorLabel', '123, 125, 141');
    root?.style.setProperty('--colorLabelRGB', '');
    root?.style.setProperty('--colorText', '46, 43, 36');
    root?.style.setProperty('--colorTextRGB', '');

    return () =>{
      window.removeEventListener('online',()=>{});
      window.removeEventListener('offline',()=>{});
    }

  },[])

  useEffect(()=>{
    // if(!location.pathname.includes('scan')){
      const root = document.documentElement;
      if(settingError) handleError(settingError)
      else if(settingData?.css){
        const parsedCss = toJSON(settingData?.css)
        const primaryColor = hexToRGB(parsedCss?.children['.btn-primary']?.attributes?.['background-color']?.split('!important')?.[0])
        const primaryColorHover = hexToRGB(parsedCss?.children['.btn-primary:hover']?.attributes?.['background-color']?.split('!important')?.[0])
        
        if(primaryColor && primaryColorHover){
          root?.style.setProperty('--colorPrimary', primaryColor);
          root?.style.setProperty('--colorPrimaryHover', primaryColorHover);
        }else{
          root?.style.setProperty('--colorPrimary', '255, 107, 0');
          root?.style.setProperty('--colorPrimaryHover', '255, 168, 0');
        }
      }else{
          root?.style.setProperty('--colorPrimary', '255, 107, 0');
          root?.style.setProperty('--colorPrimaryHover', '255, 168, 0');
      }
  // }
  },[settingData,settingError])


  useEffect(()=>{
    if(!settingLoading && !settingData && !settingError){
      handleError(new Error("Setting Not Found"))
    }
  },[settingLoading, settingData, settingError])


  return (
    <>
        {
          !settingError && settingData && !location.pathname.includes('scan') ? (
            <Helmet>
              <title>{settingData?.merchantName ? settingData?.merchantName : 'Smart Pay'}</title>
              <link rel="icon" href={`${settingData?.images?.smartwebLogo}`} />
            </Helmet>
          ) : ''
        }
        
      <RouteManager/>
    </>
  );
}

export default App;
