import HttpBEService from './http-backend-service'
import {BE_API} from '../data/constants'
import { ViewbillResponseOrderDetailItems } from './types/view-bill-service.types'
import { ddError, info } from '../utils/dd-setup'
class ViewBillService extends HttpBEService{

    constructor(){
        super()
    }

    async getBill(mode:string,page:string,signal?:AbortSignal){
       return this.get(BE_API.BILL,signal).then((res)=>{
            info(`Response at ${BE_API.BILL} mode - ${mode} page - ${page} session id - ${res.headers['x-sp-session-id']}`,{mode:`${mode} - ${page}`,...res})
            return { error:null, data: res.data.data}
        })
        .catch((err)=>{
            err.message = `${err.message} - ${BE_API.BILL} mode - ${mode} page - ${page} session id - ${err?.response?.headers['x-sp-session-id']}`;
            ddError(``,err)
            return { error:err, data:null}
        })
    }

    initialViewBillResponse(items:ViewbillResponseOrderDetailItems[]){
        return items.map((item) =>{
            let subItems = item.customisations;
            if(Array.isArray(subItems)){
                subItems = subItems.map((subItems)=>{
                    return {...subItems,open:false}
                })
            }
            if(subItems) return {...item,custom:subItems,open:false}
            return {...item,open:false}
        })
    }

    initiatePayment(data:any,signal?:AbortSignal){
        return this.post(BE_API.INITIATE_PAYMENT,data,signal).then(res=>{
            return {error:null,data:res.data.data}
        })
        .catch((err)=>{
            return { error:err, data:null}
        })
    }

    getSplitInfoData(signal?:AbortSignal) {
        return this.get(BE_API.SPLIT_INFO,signal).then((res)=>{
            return { error:null, data: res.data.data}
        })
        .catch((err)=>{
            return { error:err, data:null}
        })
    }
    
    
}


export default new ViewBillService();