import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { DD_CONFIG } from '../data/constants';

export const initDataDogBrowserLog = () => {
    datadogLogs.init({
        clientToken: DD_CONFIG.CLIENT_TOKEN,
        site: DD_CONFIG.DD_SITE as LogsInitConfiguration["site"],
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: DD_CONFIG.SERVICE_NAME
    });
    datadogLogs.logger.setLevel("info")
  };

export const info = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
          datadogLogs.logger.info(message,data)
      } catch (e) { }
    }
  };
  
export const warn = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
       datadogLogs.logger.warn(message,data)
      } catch (e) { }
    }
  };
  
export const ddError = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
        datadogLogs.logger.error(message,data)
      } catch (e) { }
    }
  };

  