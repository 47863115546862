import HttpBEService from './http-backend-service'
import {BE_API, EVENTS} from '../data/constants'
import { ddError } from '../utils/dd-setup'
import { SettingDataType } from '../store/state-types'
import { info } from '../utils/dd-setup'

class LandingPageService extends HttpBEService{

    async getData(){
       return this.get(BE_API.SETTING).then((res)=>{
            info(`${EVENTS.V1_SCAN_SETTING}_RESPONSE|merchantKey=${res.data.data.qrInfo.merchantKey}|tableNo=${res.data.data.qrInfo.tableNo}|sessionId=${res.headers['x-sp-session-id']}`,res)
            return { error:null, data: this.extractData(res.data.data,res.headers) as SettingDataType}
        })
        .catch((err)=>{
            err.message = `${EVENTS.V1_SCAN_SETTING}_RESPONSE|Error=${err.message}|sessionId=${err?.response?.headers['x-sp-session-id']}`;
            ddError(``, err)
            return { error:err, data:null}
        })
    }

    async getSettingsV2(data:any){
       info(`${EVENTS.V2_SCAN}_REQUEST|merchantKey=${data?.merchantId}|tableNo=${data?.tableNo}`, data);
       return this.post(BE_API.SETTING_V2,data).then((res)=>{
            const ddMessage = `${EVENTS.V2_SCAN}_RESPONSE|merchantKey=${data?.merchantId}|tableNo=${data?.tableNo}`
            info(ddMessage,res)
            return { error:null, data: this.extractData(res.data.data,res.headers,data) as SettingDataType}
        })
        .catch((err)=>{
            err.message = `${err.message} - ${BE_API.SETTING} session id - ${err?.response?.headers['x-sp-session-id']}`;
            ddError(``, err)
            return { error:err, data:null}
        })
    }

    extractData(data:any,headers:any,params?:any){
        const {qrInfo = {},setting,setting:{images,merchant,tipping, payment:{checkoutSetting = {},googlePaySetting = {}, applePaySetting = {}} = {}}} = data
        return {
            merchantName:merchant.name,
            merchantCountryCode:merchant.countryCode,
            posSessionId:qrInfo.posSessionId || '',
            merchantKey:merchant.key,
            tableNo:qrInfo.tableNo || params?.tableNo,
            images:{
                defaultImage:images.defaultImage,
                smartwebLogo:images.smartwebLogo,
                smartwebSplashLoading:images.smartwebSplashLoading,
                smartwebloginScreen:images.smartwebloginScreen
            },
            css:setting.layout.css,
            merchantAddr:merchant.address,
            merchantEmail:merchant.email,
            merchantPhone:merchant.whatsapp,
            currencyDetails:merchant.currencyDetail,
            paymentMappings:merchant.paymentMappings.map((payment:any)=>{
                return {...payment,isValid:true}
            }),
            maxApiChecking:merchant.maxChecking,
            socialMedia:merchant.socialMedia,
            smartPaySetting:merchant.smartpaySetting,
            xSpSessionID:headers['x-sp-session-id'],
            tipping,
            roundingRule: merchant.roundingRule,
            splitPayment: setting.splitPayment,
            checkoutSetting: {
                publicKey: checkoutSetting?.publicKey
            },
            googlePaySetting: {
                baseCardPaymentMethod:googlePaySetting?.baseCardPaymentMethod,
                baseRequest:googlePaySetting?.baseRequest,
                baseUrl:googlePaySetting?.baseUrl,
                gatewayMerchantId:googlePaySetting?.gatewayMerchantId,
                googleMerchantName:googlePaySetting?.googleMerchantName,
                googlePayEnv:googlePaySetting?.googlePayEnv,
                googlePaymentType:googlePaySetting?.googlePaymentType,
                googleTokenType:googlePaySetting?.googleTokenType,
                invokeUrl:googlePaySetting?.invokeUrl,
                mode:googlePaySetting?.mode,
                publicKey:googlePaySetting?.publicKey,
                tokenizationSpecification:googlePaySetting?.tokenizationSpecification
            },
            applePaySetting: applePaySetting
        }
    }   
}


export default new LandingPageService();